<template>
  <div>
    <button  @click="Add()" class='linkButton'>Import Student Event Eligibility</button>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <label>Import Student Event Eligibility</label>
         
            <imagecapture v-if="!pleaseWait" ref="img" :allowFileSelect='allowFileSelect' :showImage="showImage" 
                :fileAccept="fileAccept" :allowCapture="allowCapture" @gotimage="gotImage_file" fileSelectText="Click to Select File" 
                buttonLabelClass="selectclass"/>

            <div v-if="pleaseWait">
                <label class="pleasewait">Import in progress. Please wait.</label>
            </div>
            <br>
            <div>
                <label class="responsemessage">{{responseMessage}}</label>
            </div>
            <div>
                <button  @click="close()"  :disabled="pleaseWait" class="closeclass">Close</button>
            </div>
            
            
          
        </template>
      </actionmodal>
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'

import imagecapture from './ImageCapture.vue'
import datamethods from '../services/datamethods.js'
import actionmodal from './actionmodal.vue'

export default {
    name:'uploadfile',
    components:{
        imagecapture,
        actionmodal,
        
    },
    mixins:[ datamethods],
    data(){
        return{
            allowFileSelect:true,
            allowCapture:false,
            capturedFile:null,
            capturedFileURL:null,
            fileNameText:'',
            fileAccept:'*',
            showImage:false,
            message:'',

            
            responseMessage:'',
            pleaseWait:false
        }
    },
    computed:{
        ...mapFields([
      
            'username',
          
        ]),
        
    },
    methods:{
        Add(){
            this.responseMessage=''
            this.$refs.mymodal.openModal()
        },
        gotImage_file(file,url){
            console.log('gotimg' + url)
            this.capturedFile=file
            this.capturedFileURL=url
            
            this.storeImage("file", file, file.name,"file")
        },
        async storeImage(filename,file, name,type){

            var postParams = new FormData();

            postParams.append(filename,file,name);
            
            postParams.append('filename',name);
           this.pleaseWait=true
            var response = await this.callAPI(postParams,'eligibilityimport')
            //handle response
            if(response.Successful){
                //this.getBadgeDetail(); 
                this.responseMessage="Uploaded " + name 
                this.pleaseWait=false
            }else{
                this.responseMessage = response.Message
                this.pleaseWait=false
            }
             
        },
        close(){
            this.$emit('refresh')
            this.$refs.mymodal.closeModal()
        },
    }
}
</script>

<style>
.selectclass{
    background-color:var(--primary-color);
    color:white;
    padding:5px;
    border-radius: 2px;
    border:none;
}
.closeclass{
    margin-top:40px;
    font-size:large;
    background-color:var(--primary-color);
    color:white;
    padding:5px;
    border-radius: 2px;
    border:none;
}
</style>
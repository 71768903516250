<template>
    <div class="horizFlex wrap"> 
        <div v-for="i in itemList" :key="i.id" :value="i.id">
            <smallbutton :buttonclass="getbuttonclass(i)"  :toplabel="i.name" @click="select(i)"/>  
        </div> 
           
    </div>
</template>
    
<script>

    import smallbutton from './button.vue'

    export default {
        name:'optionbutton',
        components:{
            smallbutton,

        },
        props:{
            itemList:{
                type:Array,
            },
           initialSelectedId:{
            type:Number,
           },
           useLargeButtons:{
            type:Boolean,
            default:false
           }
    
        },
        data(){
            return{
                selectedId:0

            }
        },
        computed:{
            
        },
        methods:{
            
            getbuttonclass(item){
                if(this.useLargeButtons){
                    if(item.id==this.selectedId){
                        return "buttonprimary selectedoptionbuttonbig"
                    }else{
                        return "buttonprimary optionbuttonbig"
                    }

                }else{
                    if(item.id==this.selectedId){
                        return "buttonprimary selectedoptionbutton"
                    }else{
                        return "buttonprimary optionbutton"
                    }
                }
                
            },
            select(item){
                this.selectedId=item.id
                this.$emit('select', this.selectedId)
            },
            selectbyid(id){
                this.selectedId=id
                this.$emit('select', this.selectedId)
            }
            
        },
        mounted(){
            console.log('mount option ' + this.initialSelectedId)
            this.selectedId=this.initialSelectedId
        }
            
        
    }
    </script>
    
    <style>
    
    .optionbutton{
        height:40px;
        width:40px;
        font-size:inherit;
        border-radius: 2px;
        padding:3px;
        margin:0px 5px 5px 5px;
        border:none;
        
    }
    .selectedoptionbutton{
        height:40px;
        width:40px;
        font-size:inherit;
        border-radius: 2px;
        padding:3px;
        margin:0px 5px 5px 5px;
        border:4px yellow solid;
    }
    .optionbuttonbig{
        border-radius:5px;
        width:150px;
        height:50px;
        padding:3px;
        margin:5px 5px 5px 5px;
        border:none;
        font-size:inherit;
    }
    .selectedoptionbuttonbig{
        border-radius:5px;
        width:150px;
        height:50px;
        padding:3px;
        margin:5px 5px 5px 5px;
        border:4px yellow solid;
        font-size:inherit;
    }

    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
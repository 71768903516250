//import Vue from 'vue'
//import Vuex from 'vuex'

import { createStore } from 'vuex'
import {getField, updateField} from 'vuex-map-fields'

//Vue.use(Vuex)
//export const store = new Vuex.Store({
export const store = createStore({
    state:{
        //general
        username:'',
        oldpassword:'',
        password:'',
        loggedInUser:{
            name:'User',
            user_personId:0,
            LocationId:0
        },
        loggedIn:false,
        robot:true,
        
 
       
        
        pleaseWait:false,
        message:'',

        
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.message=''
        },
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})